import styled from 'styled-components';
import { isDevelop, isStage } from 'modules/Utils/Environment';
import { ZIndexes } from 'modules/Utils/ZIndexes';

export const Container = styled.div`
  z-index: ${ZIndexes.envIdentifier};
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
`;

export const IdentifierContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;

  color: ${(props) => props.theme.config.colors.light};
  background: ${(props) =>
    isDevelop
      ? props.theme.config.colors.black
      : isStage
      ? props.theme.config.colors.orange
      : props.theme.config.colors.text_base};
`;

export const IdentifierText = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${(props) => props.theme.config.spacing.spacing_1};
  }
`;

export const Env = styled.strong`
  display: block;
  margin-left: ${(props) => props.theme.config.spacing.spacing_1};
`;
