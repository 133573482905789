import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import passwordValidator from 'password-validator';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Icon, Tooltip } from 'modules/Core/Common';
import { useTheme } from 'styled-components';
import i18n from 'i18next';

import * as S from './PasswordStrength.styles';

const PasswordStrength = ({ containerElement, password, handleClose }) => {
  const theme = useTheme();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [passwordErrosList, setPasswordErrosList] = useState([]);

  const schema = new passwordValidator();
  schema.is().min(8).has().uppercase(1).has().digits(1);

  const validate = (value) => {
    const passworErros = schema.validate(value, { list: true });
    setPasswordErrosList(passworErros);
  };

  const checkErrosForModColor = (type) => {
    const error = passwordErrosList.find((element) => element === type);
    if (error) {
      return theme.config.colors.tertiary;
    }
    return theme.config.colors.green;
  };

  const checkErrosForModIcon = (type) => {
    const error = passwordErrosList.find((element) => element === type);
    if (error) return 'close';
    return 'check';
  };

  const onClose = () => {
    setTooltipVisible(!tooltipVisible);
    handleClose();
  };

  useEffect(() => {
    validate(password);
    password && setTooltipVisible(true);
  }, [password]);

  return (
    <ClickAwayListener
      onClickAway={() => tooltipVisible && setTooltipVisible(!tooltipVisible)}
    >
      <S.Container
        tooltipVisible={tooltipVisible}
        validate={!passwordErrosList.length}
      >
        {tooltipVisible && (
          <Tooltip
            overlayClassName="tooltip-light"
            placement="bottom"
            getTooltipContainer={() => containerElement}
            visible={tooltipVisible}
            overlay={
              <>
                <S.CheckStrongContainer validate={!passwordErrosList.length}>
                  <S.BarStrong>
                    <S.LineStrong validate={!passwordErrosList.length} />
                  </S.BarStrong>
                  <ul>
                    <li>
                      <Icon
                        name={!passwordErrosList.length ? 'check' : 'close'}
                      />
                      {!passwordErrosList.length
                        ? i18n.t('commons.strenght-password.strong-password')
                        : i18n.t('commons.strenght-password.weak-password')}
                    </li>
                    <li>
                      <Icon
                        name={checkErrosForModIcon('min')}
                        color={checkErrosForModColor('min')}
                      />
                      {i18n.t('commons.strenght-password.minimum-characters')}
                    </li>
                    <li>
                      <Icon
                        name={checkErrosForModIcon('digits')}
                        color={checkErrosForModColor('digits')}
                      />
                      {i18n.t('commons.strenght-password.minimum-number')}
                    </li>
                    <li>
                      <Icon
                        name={checkErrosForModIcon('uppercase')}
                        color={checkErrosForModColor('uppercase')}
                      />
                      {i18n.t(
                        'commons.strenght-password.minimum-uppercase-letter'
                      )}
                    </li>
                  </ul>
                </S.CheckStrongContainer>
              </>
            }
          >
            <Icon
              onClick={onClose}
              id="ErrorPassword"
              name={!passwordErrosList.length ? 'check' : 'close'}
            />
          </Tooltip>
        )}
      </S.Container>
    </ClickAwayListener>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string,
  handleClose: PropTypes.func,
  containerElement: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
};

export default PasswordStrength;
