import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { RCTooltip } from 'modules/Core/Common';
import { ArrowRightIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './SidebarUnclickableLink.style';

const SidebarUnclickableLink = ({
  onClick,
  isDesktop,
  activeMenuName,
  disabled,
  Icon,
  name,
}) => {
  const translatedName = i18n.t(
    `sidebar.${name?.replaceAll(' ', '-').toLowerCase()}`
  );

  return (
    <S.MenuItem
      onClick={onClick}
      className={activeMenuName === name ? 'active' : ''}
      data-name={translatedName}
      disabled={disabled}
      data-tip={name}
    >
      <S.SidebarLinkUnClickable>
        <Icon color="#0075ea" />
        {isDesktop && <RCTooltip offset={{ right: 20 }} />}
      </S.SidebarLinkUnClickable>
      {!isDesktop && (
        <S.OpenArrow>
          <ArrowRightIcon />
        </S.OpenArrow>
      )}
      {!isDesktop && translatedName}
    </S.MenuItem>
  );
};

SidebarUnclickableLink.propTypes = {
  onClick: PropTypes.func,
  activeMenuName: PropTypes.string,
  Icon: PropTypes.func,
  name: PropTypes.string,
  isDesktop: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SidebarUnclickableLink;
